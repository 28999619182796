import React, { useEffect } from 'react';
import RegattaRegistrationScreen from './RegattaRegistration';
import PersistanceService from '../PersistanceService';
import RegistrationView, { Registration } from '../Views/Registration';
import { Container, ListGroup, Stack } from 'react-bootstrap';
import RegistrationsRepository from '../Repositories/RegistrationsRepository';
import AuthenticationService from '../AuthenticationService';
import RegisterView from '../Views/Register';
import RaceRepository from '../Repositories/RaceRepository';
import UserRepository from '../Repositories/UserRepository';


const HomeScreen: React.FC = () => {
    const persistanceService = new PersistanceService();
    const [registrations, setRegistrations] = React.useState<Registration[]>([]);
    const registrationsRepository = RegistrationsRepository.instance;

    const [raceOptions, setRaceOptions] = React.useState<{ value: string; label: string; }[]>([]);
    const [rowerOptions, setRowerOptions] = React.useState<{ value: string; label: string; }[]>([]);

    const [loading, setLoading] = React.useState(true);

    const authenticationService = AuthenticationService.instance;

    useEffect(() => {
        authenticationService.onAuthStateChanged((loggedIn: boolean) => {
            if (loggedIn) {
                registrationsRepository.getRegistrations().then((registrations) => {
                    setRegistrations(registrations);
                    setLoading(false);
                }).catch((error) => {
                    console.error(error);
                });

                RaceRepository.instance.getRaces()
                .then((races) => {
                    const raceOptions = races.map((race) => ({
                        value: race.id,
                        label: race.description
                    }))
                    setRaceOptions(raceOptions);
                })

                UserRepository.instance.getAllUsers()
                .then((users) => {
                    const rowerOptions = users.map((user) => ({
                        value: `${user.id}`,
                        label: `${user.firstName} ${user.lastName}`
                    }))
                    setRowerOptions(rowerOptions);  
                });

            }
        });
        }, []);

    const alertClicked = () => {
        alert('You clicked a ListGroupItem');
    };

    return (
        <Container className="d-flex justify-content-center">
            <Container style={{maxWidth: 600, width: "100%", paddingTop: 40, paddingBottom: 20}}>
            <h2>Rådaregattan 2024</h2>
            <div style={{height: 12}}></div>
            <h6 style={{fontWeight: "bold", color: "gray"}}>ANMÄL</h6>
            <RegisterView raceOptions={raceOptions} rowerOptions={rowerOptions}/>
            <div style={{height: 60}}></div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <Container style={{maxWidth: 600, width: "100%", padding: 0}}>
            <h6 style={{fontWeight: "bold", color: "gray"}}>ANMÄLNINGAR</h6>
                    <ListGroup style={{maxWidth: 600, width: "100%", padding: 0 }}>
                        {registrations.map((registration) => (
                            <ListGroup.Item key={"registration.id"} action onClick={alertClicked}>
                                <RegistrationView registration={registration} />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Container>
            )}
            </Container>
        </Container>
    );
};


export default HomeScreen;