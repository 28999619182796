import AuthenticationService from "../AuthenticationService";

class LogoutUseCase {
    private authService = AuthenticationService.instance;
    static instance = new LogoutUseCase();

    async execute(): Promise<void> {
        await this.authService.logout();
    }
}

export default LogoutUseCase;