import { createContext, ReactNode, useEffect, useState } from 'react'
import AuthenticationService from './AuthenticationService'
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  children?: ReactNode;
}

const AuthContext = createContext<boolean>(false)

const AuthProvider = ({children}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const authenticationService = new AuthenticationService();

  const lastAuthStatus: boolean = JSON.parse(localStorage.getItem('authenticated') || 'false');

  const [authenticated, setAuthenticated] = useState(lastAuthStatus);
  
  
  useEffect(() => {
    authenticationService.onAuthStateChanged((loggedIn: boolean) => {
      console.log("AuthContext state change - logged in: " + loggedIn)
      const alreadyAuthenticated = authenticated;
      const atLoginOrSignupPage = location.pathname === '/login' || location.pathname === '/signup';
      setAuthenticated(loggedIn);
      localStorage.setItem('authenticated', loggedIn.toString());
      if ((!alreadyAuthenticated || atLoginOrSignupPage) && loggedIn) {
        const origin = location.state?.from?.pathName || '/';
        console.log("Navigating to: " + origin);
        navigate(origin);
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={authenticated}>
      {children}
    </AuthContext.Provider>
  )
}

export {  AuthContext, AuthProvider }