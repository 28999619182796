import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, Auth, setPersistence, browserLocalPersistence, User, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyD5vEMkZXGNAcPXbpte5r8l5qvoBm-so78",
    authDomain: "hemsida-75e58.firebaseapp.com",
    projectId: "hemsida-75e58",
    storageBucket: "hemsida-75e58.appspot.com",
    messagingSenderId: "119225914370",
    appId: "1:119225914370:web:9dd476fbc6647dc1b3c31b",
    measurementId: "G-QNGB04ESN7"
};

export const app = initializeApp(firebaseConfig);


class AuthenticationService {
    private static auth: Auth;
    static instance: AuthenticationService = new AuthenticationService();

    constructor() {
        AuthenticationService.auth = getAuth(app);
    }

    public async login(email: string, password: string): Promise<void> {
        setPersistence(AuthenticationService.auth, browserLocalPersistence)
            .then(() => {
                return signInWithEmailAndPassword(AuthenticationService.auth, email, password);
            })
            .catch((error) => {
                console.error('Error logging in:', error);
            });
    }

    public getUserID(): string | null {
        const user = AuthenticationService.auth.currentUser;
        console.log("User: ", user);
        return user ? user.uid : null;
    }

    public async logout(): Promise<void> {
        signOut(AuthenticationService.auth);
    }

    public async signup(email: string, password: string): Promise<void> {
        createUserWithEmailAndPassword(AuthenticationService.auth, email, password);
    }

    public onAuthStateChanged(callback: (loggedIn: boolean) => void) {
        onAuthStateChanged(AuthenticationService.auth, 
            (user: User | null) => {
                if (user) {
                    callback(true);
                } else {
                    callback(false);
                }
            }
        );                
    }
}

export default AuthenticationService;