import React, { useContext } from 'react'
import {Routes as Router, Route, Navigate, Outlet, useLocation} from 'react-router-dom'
import { AuthContext } from "./AuthProvider"
import LoginScreen from './Pages/Login';
import HomeScreen from './Pages/Home';
import SignupScreen from './Pages/Signup';
import { string } from 'yup';
import NavbarView from './Views/Navbar';
import RowersScreen from './Pages/Rowers';

type Props = {}

const PrivateRoutes = () => {
  const authenticated = useContext(AuthContext);
  const location = useLocation();

  if(!authenticated) {
    console.log("redirecting to login")
    return <Navigate to="/login" replace state={{origin: location}}/>
  }

  return <Outlet />
}



const Routes = (props: Props) => {
  return (
    <Router>
      <Route path='/login' element={<LoginScreen />}/>
      <Route path='/signup' element={<SignupScreen />}/>
      <Route element={<PrivateRoutes />}>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/rowers' element={<RowersScreen />} />
      </Route>
    </Router>
  )
}

export default Routes