import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom'; // Removed duplicate BrowserRouter
import LoginScreen from './Pages/Login';
import SignupScreen from './Pages/Signup';
import HomeScreen from './Pages/Home';
import { AuthProvider } from './AuthProvider';
import Routes from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarView from './Views/Navbar';

import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

function App() {
  return (
    <BrowserRouter>
    <AuthProvider>
      <NavbarView />
      <Routes />
      </AuthProvider>
    </BrowserRouter>    
  );
}

export default App;
