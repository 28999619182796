import React, { useContext } from 'react';
import {Form, Formik, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import AuthenticationService from '../AuthenticationService';
import { AuthContext } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';

const SignupScreen: React.FC = () => {
    const initialValues = {
        email: '',
        password: '',
        confirmPassword: '',
    };

    const authenticationService = new AuthenticationService();

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required('Required'),
    });

    const handleSubmit = async (values: typeof initialValues) => {
        authenticationService.signup(values.email, values.password)
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ width: '300px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>
                <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Signup</h1>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    <Form>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="email">Email</label>
                            <Field type="email" id="email" name="email" style={{ width: '100%', padding: '5px' }} />
                            <ErrorMessage name="email" component="div" />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="password">Password</label>
                            <Field type="password" id="password" name="password" style={{ width: '100%', padding: '5px' }} />
                            <ErrorMessage name="password" component="div"/>
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <Field type="password" id="confirmPassword" name="confirmPassword" style={{ width: '100%', padding: '5px' }} />
                            <ErrorMessage name="confirmPassword" component="div"/>
                        </div>
                        <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '5px' }}>Submit</button>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default SignupScreen;
