import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import LogoutUseCase from '../Use Cases/LogoutUseCase';

const NavbarView = () => {
  
  const handleLogout = () => {
    LogoutUseCase.instance.execute();
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container style={{maxWidth: 600, width: "100%", padding: 10}} >
        <Navbar.Brand as={Link} to="/">Tranodd Regatta</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">Anmäl</Nav.Link>
            <Nav.Link as={Link} to="/rowers">Roddare</Nav.Link>
            <Nav.Link as={Button} onClick={handleLogout}>Logga ut</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};


export default NavbarView;