import PersistenceService from "../PersistanceService";


export type Race = {
    id: string;
    description: string;
    crews: {
        id: string;
        name: string;
    }[];
    raceNumber: string;
}

class RaceRepository {
    static instance = new RaceRepository();
    private persistanceService = PersistenceService.instance;

    async getRaces(): Promise<Race[]> {
        const races: Race[] = await this.persistanceService.getDocuments("regattas/AKsiDo1CwO4033jnSK2S/races");
        console.log("races: " + races);
        return races;
    }
}

export default RaceRepository;