import AuthenticationService from "../AuthenticationService";
import RegistrationsRepository from "../Repositories/RegistrationsRepository";
import UserRepository from "../Repositories/UserRepository";
import { Registration } from "../Views/Registration";

class RegisterUseCase {
    private repository = RegistrationsRepository.instance;
    private authService = AuthenticationService.instance;
    private userRepository = UserRepository.instance;

    async execute(registration: Registration) {
        const userId = await this.authService.getUserID();
        if (userId) {
            const savableRegistration = registration as Omit<Registration, "id">;
            const user = await this.userRepository.getUserById(userId);
            savableRegistration.responsibleId = userId;
            savableRegistration.responsibleName = `${user.firstName} ${user.lastName}`;
            this.repository.register(savableRegistration);
        }
    }
}

export default RegisterUseCase;