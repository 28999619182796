import { get } from 'http';
import { app } from './AuthenticationService';
import { getFirestore, addDoc, getDoc, getDocs, collection, doc, Firestore, DocumentData, query, where } from 'firebase/firestore';

interface Identifiable {
    id: string;
}

class PersistenceService {
    private firestore = getFirestore(app)
    static instance: PersistenceService = new PersistenceService();

    async getDocument<T extends Identifiable>(path: string, documentId: string): Promise<T> {
        const documentRef = doc(this.firestore, path, documentId);
        const documentSnapshot = await getDoc(documentRef);
        return { ...documentSnapshot.data(), id: documentId } as T;
    }

    async getDocumentsByField<T extends Identifiable>(path: string, field: string, value: any): Promise<T[]> {
        console.log("Getting documents by field...");
        console.log("Path: ", path);
        console.log("Field: ", field);
        console.log("Value: ", value);
        const documentsRef = collection(this.firestore, path);
        const documentsQuery = query(documentsRef, where(field, '==', value));
        const querySnapshot = await getDocs(documentsQuery);
        return querySnapshot.docs.map((doc) => {
            const data = doc.data();
            const documentId = doc.id;
            return { ...data, id: documentId } as T;
        });
    }
    async getDocuments<T extends Identifiable>(path: string): Promise<T[]> {
        const documentsRef = collection(this.firestore, path);
        const documentsSnapshot = await getDocs(documentsRef);
        return documentsSnapshot.docs.map((doc) => {
            const data = doc.data();
            const documentId = doc.id;
            return { ...data, id: documentId } as T;
        });
    }

    async saveDocument(path: string, document: any): Promise<void> {
        const reference = collection(this.firestore, path);
        await addDoc(reference, document);
    }

}

export default PersistenceService;