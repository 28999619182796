import PersistenceService from "../PersistanceService";

export type User = {
    id: string;
    firstName: string;
    lastName: string;
    club: string;
}

class UserRepository {
    private persistanceService = PersistenceService.instance;
    static instance: UserRepository = new UserRepository();

    async getAllUsers() {
        return this.persistanceService.getDocuments<User>("accounts")
            .then((users) => {
            console.log("Users fetched successfully. Users: ", users);
            return users;
            });
    }

    async getUserById(userId: string) {
        return this.persistanceService.getDocument<User>("accounts", userId)
            .then((user) => {
            console.log("User fetched successfully. User: ", user);
            return user;
            });
    }
}

export default UserRepository;