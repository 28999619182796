import React from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";

export type Registration = {
    id: string;
    raceId: string;
    lateRegistration: boolean;
    registrationDate: {
        seconds: number;
        nanoseconds: number;
    };
    raceNumber: string;
    responsibleId: string;
    responsibleName: string;
    clubs: {
        id: string;
        name: string;
    }[];
    crew: {
        rowerId: string;
        name: string;
    }[];
}


const RegistrationView: React.FC<{ registration: Registration }> = ({ registration }) => {
    
    function formatDate(registrationDate: { seconds: number; nanoseconds: number }): string {
        // Create a new Date object from the seconds
        const date = new Date(registrationDate.seconds * 1000);
    
        // Get the year, month, and day
        const year = date.getFullYear().toString().substr(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0-indexed in JavaScript
        const day = ('0' + date.getDate()).slice(-2);
    
        // Return the formatted date
        return `${year}-${month}-${day}`;
    }
    
    return (
        <Stack>
            <Stack direction="horizontal" style={{justifyContent: "space-between"}}>
                <p style={{margin: 0}}>{registration.raceNumber}</p>
            <p style={{margin: 0}}>{formatDate(registration.registrationDate)}</p>
            </Stack>
            <Stack>
            <>{registration.crew.map((rower) => (
                <p key={rower.rowerId} style={{margin: 0}}>{rower.name}</p>
            ))}</>
            </Stack>
        </Stack>
        
    );
}


export default RegistrationView;