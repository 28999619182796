import AuthenticationService from '../AuthenticationService';
import PersistenceService from '../PersistanceService';
import { Registration } from '../Views/Registration';

class RegistrationsRepository {
    private persistanceService = PersistenceService.instance;
    private authService = AuthenticationService.instance;
    static instance: RegistrationsRepository = new RegistrationsRepository();

    
    async getRegistrations(): Promise<Registration[]> {
        console.log("Fetching registrations...");

        try {
            const userId = this.authService.getUserID();
            if (!userId) {
                throw new Error("User not logged in");
            }

            console.log("Getting documents from persistence service...");
            const registrations: Registration[] = await this.persistanceService.getDocumentsByField("regattas/AKsiDo1CwO4033jnSK2S/registrations", "responsibleId", userId);
            console.log("Registrations fetched successfully. Registrations: ", registrations);
            return registrations;
        } catch (error) {
            console.error("Error fetching registrations:", error);
            throw error;
        }
    }

    register(registration: Omit<Registration, "id">): Promise<void> {
        console.log("Creating registration...");
        return this.persistanceService.saveDocument("regattas/AKsiDo1CwO4033jnSK2S/registrations", registration);
    }
    
};

export default RegistrationsRepository;