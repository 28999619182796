import React, { useContext } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthenticationService from '../AuthenticationService';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthProvider';

const LoginScreen: React.FC = () => {
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        password: Yup.string().required('Required'),
    });
    const authenticationService = new AuthenticationService();

    const initialValues = {
        email: '',
        password: '',
    };

    const authenticated = useContext(AuthContext)


    const handleSubmit = (values: typeof initialValues) => {
        console.log('Trying to log in');
        authenticationService.login(values.email, values.password);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ width: '300px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>
                <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Login</h1>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    <Form>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="email">Email</label>
                            <Field type="email" id="email" name="email" style={{ width: '100%', padding: '5px' }} />
                            <ErrorMessage name="email" component="div" />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="password">Password</label>
                            <Field type="password" id="password" name="password" style={{ width: '100%', padding: '5px' }} />
                            <ErrorMessage name="password" component="div"/>
                        </div>
                        <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '5px' }}>Submit</button>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default LoginScreen;