import React, { useState } from 'react';
import { Form, Button, Stack } from 'react-bootstrap';
import { Registration } from './Registration';
import Select, { MultiValue, OptionsOrGroups } from 'react-select';
import RegisterUseCase from '../Use Cases/RegisterUseCase';

export type FormOption = {
    value: string;
    label: string;
}

const Register: React.FC<{ raceOptions: FormOption[], rowerOptions: FormOption[]}> = ({ raceOptions, rowerOptions }) => {
    const register = new RegisterUseCase();
    const emptyRegistration: Registration = {
            id: '',
            raceId: '',
            lateRegistration: false,
            registrationDate: {
                seconds: 0,
                nanoseconds: 0,
            },
            raceNumber: '',
            responsibleId: '',
            responsibleName: '',
            clubs: [],
            crew: [],
        }
        const [registration, setRegistration] = useState<Registration>(emptyRegistration);

    const handleRaceChange = (newValue: any) => {
        setRegistration(prevRegistration => ({ ...prevRegistration, raceId: newValue.value }));
    };

    const handleRowersChange = (newValue: MultiValue<{ value: string; label: string; }>) => {
        const newCrew = newValue.map(option => ({ name: option.label, rowerId: option.value }));
        setRegistration(prevRegistration => ({ ...prevRegistration, crew: newCrew }));
    };

    const handleSubmit = () => {
        register.execute(registration);
        setRegistration(emptyRegistration);
    };


    return (
        <Stack gap={2}>
            <div>
                <Select
                    name="race"
                    value={raceOptions.find(option => option.value === registration.raceId)}
                    onChange={handleRaceChange}
                    options={raceOptions}
                />
            </div>
            <div >
                <Select 
                    isMulti
                    name="rowers"
                    value={registration.crew.map(rower => ({ value: rower.rowerId, label: rower.name }))}
                    onChange={handleRowersChange}
                    options={rowerOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isOptionDisabled={(option) => registration.crew.length >= 2}
                />
            </div>
            <Button variant="primary" type="submit" disabled={false} onClick={handleSubmit}>
                    Anmäl
            </Button>            
        </Stack>
    );
};

export default Register;